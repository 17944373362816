import React from "react"
// import { useGetSolvesQuery } from "../solves/solvesApiSlice"

const Minicomps = () => {
    // const {
    //     data: solves,
    //     isFetching,
    //     isSuccess,
    //     isError,
    //     error,
    // } = useGetSolvesQuery("solvesList", {
    //     pollingInterval: 30 * 60 * 1000,
    //     refetchOnFocus: true,
    //     refetchOnMountOrArgChange: true,
    // })

    // let solves
    // if (isFetching) {
    //     eventItems = <p>Loading...</p>
    // } else if (isSuccess) {
    //     const { ids } = events
    //     eventItems =
    //         ids?.length &&
    //         ids.map((id) => (
    //             <Col key={id} md={4} lg={3}>
    //                 <EventItem eventId={id} />
    //             </Col>
    //         ))
    // } else if (isError) {
    //     eventItems = <p>{error}</p>
    // }

    return (
        <div>
            IN PROGRESS
            {/* {isSuccess && (
                <ul>
                    {solves.map((solve) => (
                        <li>solve.id</li>
                    ))}
                </ul>
            )} */}
        </div>
    )
}

export default Minicomps
