import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import useTitle from "../hooks/useTitle"
import bgImg from "../images/bg.webp"
import BackgroundImage from "./BackgroundImage"
import Header from "./Header"

import MiniCompLeaderboard from "../features/minicomp/MiniCompLeaderboard"
import SocietyNews from "../features/news/SocietyNews"
import ErrorBoundary from "./ErrorBoundary"

const Home = () => {
    useTitle("Home")
    return (
        <>
            <BackgroundImage img={bgImg}>
                <Header title="Warwick Rubik's Cube Society">
                    <p className="lead">
                        Whether you are a cubing veteran or have never touched a
                        cube before, there is room for everyone{" "}
                        <FontAwesomeIcon icon="face-smile" />
                    </p>
                </Header>
            </BackgroundImage>

            <main>
                <Row className="m-4">
                    <Col xxl={6} lg={12}>
                        <ErrorBoundary>
                            <SocietyNews />
                        </ErrorBoundary>
                    </Col>
                    <Col xxl={6} lg={12}>
                        <MiniCompLeaderboard />
                    </Col>
                </Row>
            </main>
        </>
    )
}

export default Home
